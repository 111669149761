<template>
  <div>
    <div class="mb-2 flex flex-col ">
      <span class="text-xl font-bold text-red-700">Disclaimer. </span>
      <span class="text-sm ">
        The videos featured on this site are not owned by us. All
        videos are hosted on third-party video players and platforms, which we
        do not control. We simply provide a platform for these videos to be
        accessed. All copyrights and intellectual property rights belong to the
        original content creators or owners. If you have any concerns regarding
        the content, please contact the respective third-party hosting site
        directly.
      </span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>