<template>
  <div class="md:mx-[370px]">
    <Navbar />
    <router-view />
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";

export default {
  components: {
    Navbar,
  },
  data: () => ({
    title: "trial",
  }),
};
</script>
<style>
html {
  background-color: #020817;
  color: #eeeef0;
}
</style>
