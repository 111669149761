import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView'
import KDrama from '@/views/KDrama'
import Movies from '@/views/Movies'
import PhMovies from '@/views/PhMovies'
// nested

import PhMoviePage from '@/components/PHmovies/PhMoviePage'
import KdramaMoviesPage from '@/components/KDrama/KdramaMoviesPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 
  {
    path: '/kdrama',
    name: 'KDrama',
    component: KDrama
  },
  {
    path: '/kdrama/:titleSlug',
    name: 'KdramaMoviesPage',
    component: KdramaMoviesPage
    // 
  },
  {
    path: '/movies/',
    name: 'Movies',
    component: Movies,
  },
  // 
  {
    path: '/ph-movies/:titleSlug',
    name: 'PinoyMoviesPage',
    component: PhMoviePage
  },
  {
    path: '/ph-movies',
    name: 'PhMovies',
    component: PhMovies
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
