<template>
  <nav class="flex flex-row justify-between items-center bg-[#1e293b] h-10 px-2">
    <span>SearchNow</span>
    <div class="">
      <ul class="md:flex hidden gap-4 ">
        <li v-for="(item ,i)  in navItems" :key="i" class="flex gap-2" >
            <router-link :to="item.link">{{ item.name }}</router-link>
        </li>
      </ul>
      <!-- for small screen  -->
      <div
      v-if="isOpen"
      @click="toggleMenu"
      class="bg-black opacity-50  absolute  inset-0 md:hidden"
    ></div>
      <button v-if="!isOpen" @click="toggleMenu" class="p-2  flex md:hidden uppercase " >
        <box-icon  name="menu" color="white" ></box-icon>
      </button>
      
        <ul v-if="(isOpen)"
          class="absolute w-[50%] flex flex-col z-100 md:hidden gap-2 top-0 right-0 bg-[#1e293b] h-full">
          <button v-if="isOpen" @click="toggleMenu" class="p-2 mb-5 flex justify-end md:hidden">
            <box-icon  name="x" color="white" ></box-icon>
          </button>
          <li v-for="(item ,i)  in navItems" :key="i" class="flex gap-2 justify-center" >
            <router-link :to="item.link">{{ item.name }}</router-link>
          </li>
        </ul>
        
    </div>
  </nav>
</template>

<script>
export default {
  data: () => ({
    isOpen: false,
    navItems: [
        {
            name : 'Home' , link: '/'
        },
        {
            name : 'Kdrama' , link: '/kdrama'
        },
        {
            name : 'Movies' , link: '/movies'
        },
        {
            name : 'PH-Movies' , link: '/ph-movies'
        },
    ]
  }),
  methods: {
    toggleMenu() {
      let body = document.body;
      this.isOpen = !this.isOpen;
      this.isOpen
        ? body.classList.add("overflow-hidden")
        : body.classList.remove("overflow-hidden");
    },
  },
};
</script>

<style>
</style>