<template>
  <div class="md:px-0 px-1.5 ">
    <h1 class="text-center text-2xl uppercase">{{ movieTitle }}</h1>
    <ul v-if="episodes.length">
      <li v-for="episode in episodes" :key="episode.title">
        <iframe
          width="100%"
          height="360"
          scrolling="no"
          :src="episode.watchUrl"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <!-- <span>{{ episode.title }}</span> -->
        <div class="flex justify-center mt-2">
          <button class="bg-green-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" @click="watchEpisode(episode.title)">Watch Now</button>
        <button class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" @click="downloadEpisode(episode.title)">Download</button>
        </div>
      </li>
    </ul>
    <p v-else>No episodes available.</p>
    <!-- Display iframe if an episode is selected -->
  </div>
</template>

<script>
export default {
  name: "PhMoviePage",
  data: () => ({
    episodes: [],
    currentEpisode: null, // Store the currently selected episode
  }),
  computed: {
    movieTitle() {
      return this.$route.params.titleSlug.replace(/-/g, " ");
    },
  },
  created() {
    this.loadEpisodes();
  },
  methods: {
    loadEpisodes() {
      const titleSlug = this.$route.params.titleSlug;

      // Define episodes for each movie slug with clickable actions
      const episodesData = {
        "package-deal": [
          {
            title: "package-deal",
            watchUrl:
              "https://filemoon.sx/e/8hw70axuiuh6/PACKAGE_DEAL_-_Viva_Films_2024_720p_HC_ESubs_PMH.mp4",
              downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        init: [
          {
            title: "Episode 1",
            watchUrl: "https://advertisertape.com/e/b13P9Klg7GfDkb/",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        kaskasero: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7638705310327",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        maharot: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7638705310327",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        kaulayaw: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7181358926555",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        hiraya: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7609222695543",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        maliko: [
          {
            title: "Episode 1",
            watchUrl: "https://streamtape.com/e/YGljlYooKytvv8O/",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "top-1": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7155640634075",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        huwad: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7887551793870",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "nurse-abi": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7883800054478",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        cita: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7883799399118",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "sisid-marino": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7563386948215",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        linya: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7883799661262",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "mahal-ko-ang-mahal-mo": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7883799792334",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        serbidoras: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7883799595726",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        foursome: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7879357172430",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        sugapa: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878846057166",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        kulong: [
          {
            title: "Episode 1",
            watchUrl:
              "https://hugh.cdn.rumble.cloud/video/s8/2/f/6/I/N/f6INr.gaa.mp4",
              downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        haslers: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6734669286049",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        hilom: [
          {
            title: "Episode 1",
            watchUrl: "https://hugh.cdn.rumble.cloud/video/fw/s8/2/O/0/C/B/O0CBr.caa.mp4",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        balinsasayaw: [
          {
            title: "Episode 1",
            watchUrl: "https://hugh.cdn.rumble.cloud/video/fw/s8/2/e/1/C/B/e1CBr.caa.mp4",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "dirty-ice-cream": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7883799923406",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "lady-guard": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7883800120014",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "red-flag": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7403648584311",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        dayo: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878042782414",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "wanted-girlfriend": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7346913217143",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "late-bloomer": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7346913217143",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "sweet-release": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7350583626359",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "ganti-ganti": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7350501771895",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        cheaters: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6741548141311",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        kasalo: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6731581950719",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        tl: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7881322269390",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        rita: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875711863502",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        mapanukso: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7013701651105",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        // blocked video need to change
        salisihan: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6723368848091",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        hibang: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7879397280462",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "the-influencer": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876493970126",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        eks: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875659893454",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        kabit: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875701967566",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        salitan: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7345075325559",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        takas: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6652445461085",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        katas: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6708450822875",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "palipat-lipat-papalit-palit": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6731622124287",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        salawahan: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875606088398",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "pantasya-ni-tami": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6708450560731",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        dilig: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7870723721934",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "room-service": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7879357696718",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "karinyo-brutal": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7879357631182",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        higop: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7879239404238",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        ahasss: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6569127447201",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        creak: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7870719920846",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "wild-flowers": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878993513166",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        bula: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876299393742",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "patikim-tikim": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6465860930209",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "call-me-alma": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878845860558",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        manyak: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878613011150",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        tayuan: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878612486862",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "star-dancer": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878613076686",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        kabayo: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878612814542",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        paupahan: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878049467086",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "balik-taya": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878042651342",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        suki: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878049860302",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "selinas-gold": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876413164238",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        doblado: [
          {
            title: "Episode 1",
            watchUrl: "/watch/doblado/episode-1",
            downloadUrl: "https://streamtape.com/e/xkAdeM2vXOIkKa9/",
          },
        ],
        kaliwaan: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7026301471384",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "island-of-desire": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875879307982",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "kinsenas-katapusan": [
          {
            title: "Episode 1",
            watchUrl: "https://sp.rmbl.ws/s8/2/6/U/2/4/6U24q.baa.mp4",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        palitan: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876052388558",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        daddysitter: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7666322901623",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        flirtatious: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878993447630",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "boso-dos": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878042585806",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        bedspacer: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7879357762254",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "home-service": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/6584131390045",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        pabuya: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876494035662",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "do-you-think-i-am-s3xy": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876235758286",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "lampas-langit": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876240411342",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        laruan: [
          {
            title: "Episode 1",
            watchUrl: "https://hugh.cdn.rumble.cloud/video/fw/s8/2/Q/Z/C/B/QZCBr.caa.mp4",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        biyak: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7456048220791",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        hugot: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875734997710",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "x-deal-2": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875907881678",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "bela-luna": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7877804034766",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "sabel-is-still-young": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7877804231374",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        sandwich: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7867541752526",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "s3x-games": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878049794766",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "silip-sa-apoy": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7875761277646",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        eva: [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876052191950",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "bjj-woman-on-top": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7878993382094",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
        "kitty-k7": [
          {
            title: "Episode 1",
            watchUrl: "https://ok.ru/videoembed/7876052585166",
            downloadUrl: "https://watchmovie.life/vvmax",
          },
        ],
      };

      // Load the episodes for the current movie slug
      this.episodes = episodesData[titleSlug] || [];
    },
    watchEpisode(title) {
      const episode = this.episodes.find((e) => e.title === title);
      if (episode) {
        window.location.href = episode.watchUrl; // Use window.location.href for an external link
      }
    },
    downloadEpisode(title) {
      const episode = this.episodes.find((e) => e.title === title);
      if (episode) {
        window.location.href = episode.downloadUrl; // Use window.location.href for the download link
      }
    },
  },
};
</script>

<style scoped>
button {
  margin-left: 10px;
}
</style>
