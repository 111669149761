<template>
    <div>
      <h1>{{ movieTitle }}</h1>
      <ul v-if="episodes.length">
        <li v-for="episode in episodes" :key="episode.title">
          <span>{{ episode.title }}</span>
          <button @click="watchEpisode(episode.title)">Watch</button>
          <button @click="downloadEpisode(episode.title)">Download</button>
        </li>
      </ul>
      <p v-else>No episodes available.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "Kdrama",
    data:()=>({
        episodes: [],
    }),
    computed: {
      movieTitle() {
        return this.$route.params.titleSlug.replace(/-/g, " ");
      },
    },
    created() {
      this.loadEpisodes();
    },
    methods: {
      loadEpisodes() {
        const titleSlug = this.$route.params.titleSlug;
  
        // Define episodes for each movie slug with clickable actions
        const episodesData = {
          "good-partner-2024": [
            {
              title: "Episode 1",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE0NTQ4&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 2",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE0NTQ5&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 3",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE0NTY3&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 4",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE0NTY4&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 5",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE0OTk4&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 6",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE0OTk5&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 7",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE0OTk5&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 8",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE2NzY5&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 9",
              watchUrl: "https://asianbxkiun.pro/play.php?id=NDE2Nzcw&title=Good+Partner+%282024%29&typesub=SUB&sub=&cover=Y292ZXIvLWdvb2QtcGFydG5lci0xNzE4MzU1MzQ1LnBuZw==",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 10",
              watchUrl: "",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 11",
              watchUrl: "",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 12",
              watchUrl: "",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 13",
              watchUrl: "",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 14",
              watchUrl: "",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 15",
              watchUrl: "",
              downloadUrl: "https://google.com",
            },
            {
              title: "Episode 16",
              watchUrl: "",
              downloadUrl: "https://google.com",
            },
            
          ],
          init: [
            {
              title: "Episode 1",
              watchUrl: "/watch/init/episode-1",
              downloadUrl: "/download/init/episode-1",
            },
            {
              title: "Episode 2",
              watchUrl: "/watch/init/episode-2",
              downloadUrl: "/download/init/episode-2",
            },
          ],
          kaskasero: [
            {
              title: "Episode 1",
              watchUrl: "/watch/kaskasero/episode-1",
              downloadUrl: "/download/kaskasero/episode-1",
            },
            {
              title: "Episode 2",
              watchUrl: "/watch/kaskasero/episode-2",
              downloadUrl: "/download/kaskasero/episode-2",
            },
          ],
          maharot: [
            {
              title: "Episode 1",
              watchUrl: "/watch/maharot/episode-1",
              downloadUrl: "/download/maharot/episode-1",
            },
            {
              title: "Episode 2",
              watchUrl: "/watch/maharot/episode-2",
              downloadUrl: "/download/maharot/episode-2",
            },
          ],
          kaulayaw: [
            {
              title: "Episode 1",
              watchUrl: "/watch/kaulayaw/episode-1",
              downloadUrl: "/download/kaulayaw/episode-1",
            },
          ],
          hiraya: [
            {
              title: "Episode 1",
              watchUrl: "/watch/hiraya/episode-1",
              downloadUrl: "/download/hiraya/episode-1",
            },
          ],
          maliko: [
            {
              title: "Episode 1",
              watchUrl: "/watch/maliko/episode-1",
              downloadUrl: "/download/maliko/episode-1",
            },
          ],
          "top-1": [
            {
              title: "Episode 1",
              watchUrl: "/watch/top-1/episode-1",
              downloadUrl: "/download/top-1/episode-1",
            },
          ],
          huwad: [
            {
              title: "Episode 1",
              watchUrl: "/watch/huwad/episode-1",
              downloadUrl: "/download/huwad/episode-1",
            },
          ],
          "nurse-abi": [
            {
              title: "Episode 1",
              watchUrl: "/watch/nurse-abi/episode-1",
              downloadUrl: "/download/nurse-abi/episode-1",
            },
          ],
          cita: [
            {
              title: "Episode 1",
              watchUrl: "/watch/cita/episode-1",
              downloadUrl: "/download/cita/episode-1",
            },
          ],
          "sisid-marino": [
            {
              title: "Episode 1",
              watchUrl: "/watch/sisid-marino/episode-1",
              downloadUrl: "/download/sisid-marino/episode-1",
            },
          ],
          linya: [
            {
              title: "Episode 1",
              watchUrl: "/watch/linya/episode-1",
              downloadUrl: "/download/linya/episode-1",
            },
          ],
          "mahal-ko-ang-mahal-mo": [
            {
              title: "Episode 1",
              watchUrl: "/watch/mahal-ko-ang-mahal-mo/episode-1",
              downloadUrl: "/download/mahal-ko-ang-mahal-mo/episode-1",
            },
          ],
          serbidoras: [
            {
              title: "Episode 1",
              watchUrl: "/watch/serbidoras/episode-1",
              downloadUrl: "/download/serbidoras/episode-1",
            },
          ],
          foursome: [
            {
              title: "Episode 1",
              watchUrl: "/watch/foursome/episode-1",
              downloadUrl: "/download/foursome/episode-1",
            },
          ],
          sugapa: [
            {
              title: "Episode 1",
              watchUrl: "/watch/sugapa/episode-1",
              downloadUrl: "/download/sugapa/episode-1",
            },
          ],
          kulong: [
            {
              title: "Episode 1",
              watchUrl: "/watch/kulong/episode-1",
              downloadUrl: "/download/kulong/episode-1",
            },
          ],
          haslers: [
            {
              title: "Episode 1",
              watchUrl: "/watch/haslers/episode-1",
              downloadUrl: "/download/haslers/episode-1",
            },
          ],
          hilom: [
            {
              title: "Episode 1",
              watchUrl: "/watch/hilom/episode-1",
              downloadUrl: "/download/hilom/episode-1",
            },
          ],
          balinsasayaw: [
            {
              title: "Episode 1",
              watchUrl: "/watch/balinsasayaw/episode-1",
              downloadUrl: "/download/balinsasayaw/episode-1",
            },
          ],
          "dirty-ice-cream": [
            {
              title: "Episode 1",
              watchUrl: "/watch/dirty-ice-cream/episode-1",
              downloadUrl: "/download/dirty-ice-cream/episode-1",
            },
          ],
          "lady-guard": [
            {
              title: "Episode 1",
              watchUrl: "/watch/lady-guard/episode-1",
              downloadUrl: "/download/lady-guard/episode-1",
            },
          ],
          "red-flag": [
            {
              title: "Episode 1",
              watchUrl: "/watch/red-flag/episode-1",
              downloadUrl: "/download/red-flag/episode-1",
            },
          ],
          dayo: [
            {
              title: "Episode 1",
              watchUrl: "/watch/dayo/episode-1",
              downloadUrl: "/download/dayo/episode-1",
            },
          ],
          "wanted-girlfriend": [
            {
              title: "Episode 1",
              watchUrl: "/watch/wanted-girlfriend/episode-1",
              downloadUrl: "/download/wanted-girlfriend/episode-1",
            },
          ],
          "late-bloomer": [
            {
              title: "Episode 1",
              watchUrl: "/watch/late-bloomer/episode-1",
              downloadUrl: "/download/late-bloomer/episode-1",
            },
          ],
          "sweet-release": [
            {
              title: "Episode 1",
              watchUrl: "/watch/sweet-release/episode-1",
              downloadUrl: "/download/sweet-release/episode-1",
            },
          ],
          "ganti-ganti": [
            {
              title: "Episode 1",
              watchUrl: "/watch/ganti-ganti/episode-1",
              downloadUrl: "/download/ganti-ganti/episode-1",
            },
          ],
          cheaters: [
            {
              title: "Episode 1",
              watchUrl: "/watch/cheaters/episode-1",
              downloadUrl: "/download/cheaters/episode-1",
            },
          ],
          kasalo: [
            {
              title: "Episode 1",
              watchUrl: "/watch/kasalo/episode-1",
              downloadUrl: "/download/kasalo/episode-1",
            },
          ],
          tl: [
            {
              title: "Episode 1",
              watchUrl: "/watch/tl/episode-1",
              downloadUrl: "/download/tl/episode-1",
            },
          ],
          rita: [
            {
              title: "Episode 1",
              watchUrl: "/watch/rita/episode-1",
              downloadUrl: "/download/rita/episode-1",
            },
          ],
          mapanukso: [
            {
              title: "Episode 1",
              watchUrl: "/watch/mapanukso/episode-1",
              downloadUrl: "/download/mapanukso/episode-1",
            },
          ],
          salisihan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/salisihan/episode-1",
              downloadUrl: "/download/salisihan/episode-1",
            },
          ],
          hibang: [
            {
              title: "Episode 1",
              watchUrl: "/watch/hibang/episode-1",
              downloadUrl: "/download/hibang/episode-1",
            },
          ],
          "the-influencer": [
            {
              title: "Episode 1",
              watchUrl: "/watch/the-influencer/episode-1",
              downloadUrl: "/download/the-influencer/episode-1",
            },
          ],
          eks: [
            {
              title: "Episode 1",
              watchUrl: "/watch/eks/episode-1",
              downloadUrl: "/download/eks/episode-1",
            },
          ],
          kabit: [
            {
              title: "Episode 1",
              watchUrl: "/watch/kabit/episode-1",
              downloadUrl: "/download/kabit/episode-1",
            },
          ],
          salitan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/salitan/episode-1",
              downloadUrl: "/download/salitan/episode-1",
            },
          ],
          takas: [
            {
              title: "Episode 1",
              watchUrl: "/watch/takas/episode-1",
              downloadUrl: "/download/takas/episode-1",
            },
          ],
          katas: [
            {
              title: "Episode 1",
              watchUrl: "/watch/katas/episode-1",
              downloadUrl: "/download/katas/episode-1",
            },
          ],
          "palipat-lipat-papalit-palit": [
            {
              title: "Episode 1",
              watchUrl: "/watch/palipat-lipat-papalit-palit/episode-1",
              downloadUrl: "/download/palipat-lipat-papalit-palit/episode-1",
            },
          ],
          salawahan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/salawahan/episode-1",
              downloadUrl: "/download/salawahan/episode-1",
            },
          ],
          "pantasya-ni-tami": [
            {
              title: "Episode 1",
              watchUrl: "/watch/pantasya-ni-tami/episode-1",
              downloadUrl: "/download/pantasya-ni-tami/episode-1",
            },
          ],
          dilig: [
            {
              title: "Episode 1",
              watchUrl: "/watch/dilig/episode-1",
              downloadUrl: "/download/dilig/episode-1",
            },
          ],
          "room-service": [
            {
              title: "Episode 1",
              watchUrl: "/watch/room-service/episode-1",
              downloadUrl: "/download/room-service/episode-1",
            },
          ],
          "karinyo-brutal": [
            {
              title: "Episode 1",
              watchUrl: "/watch/karinyo-brutal/episode-1",
              downloadUrl: "/download/karinyo-brutal/episode-1",
            },
          ],
          higop: [
            {
              title: "Episode 1",
              watchUrl: "/watch/higop/episode-1",
              downloadUrl: "/download/higop/episode-1",
            },
          ],
          ahasss: [
            {
              title: "Episode 1",
              watchUrl: "/watch/ahasss/episode-1",
              downloadUrl: "/download/ahasss/episode-1",
            },
          ],
          creak: [
            {
              title: "Episode 1",
              watchUrl: "/watch/creak/episode-1",
              downloadUrl: "/download/creak/episode-1",
            },
          ],
          "wild-flowers": [
            {
              title: "Episode 1",
              watchUrl: "/watch/wild-flowers/episode-1",
              downloadUrl: "/download/wild-flowers/episode-1",
            },
          ],
          bula: [
            {
              title: "Episode 1",
              watchUrl: "/watch/bula/episode-1",
              downloadUrl: "/download/bula/episode-1",
            },
          ],
          "patikim-tikim": [
            {
              title: "Episode 1",
              watchUrl: "/watch/patikim-tikim/episode-1",
              downloadUrl: "/download/patikim-tikim/episode-1",
            },
          ],
          "call-me-alma": [
            {
              title: "Episode 1",
              watchUrl: "/watch/call-me-alma/episode-1",
              downloadUrl: "/download/call-me-alma/episode-1",
            },
          ],
          manyak: [
            {
              title: "Episode 1",
              watchUrl: "/watch/manyak/episode-1",
              downloadUrl: "/download/manyak/episode-1",
            },
          ],
          tayuan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/tayuan/episode-1",
              downloadUrl: "/download/tayuan/episode-1",
            },
          ],
          "star-dancer": [
            {
              title: "Episode 1",
              watchUrl: "/watch/star-dancer/episode-1",
              downloadUrl: "/download/star-dancer/episode-1",
            },
          ],
          kabayo: [
            {
              title: "Episode 1",
              watchUrl: "/watch/kabayo/episode-1",
              downloadUrl: "/download/kabayo/episode-1",
            },
          ],
          paupahan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/paupahan/episode-1",
              downloadUrl: "/download/paupahan/episode-1",
            },
          ],
          "balik-taya": [
            {
              title: "Episode 1",
              watchUrl: "/watch/balik-taya/episode-1",
              downloadUrl: "/download/balik-taya/episode-1",
            },
          ],
          suki: [
            {
              title: "Episode 1",
              watchUrl: "/watch/suki/episode-1",
              downloadUrl: "/download/suki/episode-1",
            },
          ],
          "selinas-gold": [
            {
              title: "Episode 1",
              watchUrl: "/watch/selinas-gold/episode-1",
              downloadUrl: "/download/selinas-gold/episode-1",
            },
          ],
          doblado: [
            {
              title: "Episode 1",
              watchUrl: "/watch/doblado/episode-1",
              downloadUrl: "/download/doblado/episode-1",
            },
          ],
          kaliwaan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/kaliwaan/episode-1",
              downloadUrl: "/download/kaliwaan/episode-1",
            },
          ],
          "island-of-desire": [
            {
              title: "Episode 1",
              watchUrl: "/watch/island-of-desire/episode-1",
              downloadUrl: "/download/island-of-desire/episode-1",
            },
          ],
          "kinsenas-katapusan": [
            {
              title: "Episode 1",
              watchUrl: "/watch/kinsenas-katapusan/episode-1",
              downloadUrl: "/download/kinsenas-katapusan/episode-1",
            },
          ],
          palitan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/palitan/episode-1",
              downloadUrl: "/download/palitan/episode-1",
            },
          ],
          daddysitter: [
            {
              title: "Episode 1",
              watchUrl: "/watch/daddysitter/episode-1",
              downloadUrl: "/download/daddysitter/episode-1",
            },
          ],
          flirtatious: [
            {
              title: "Episode 1",
              watchUrl: "/watch/flirtatious/episode-1",
              downloadUrl: "/download/flirtatious/episode-1",
            },
          ],
          "boso-dos": [
            {
              title: "Episode 1",
              watchUrl: "/watch/boso-dos/episode-1",
              downloadUrl: "/download/boso-dos/episode-1",
            },
          ],
          bedspacer: [
            {
              title: "Episode 1",
              watchUrl: "/watch/bedspacer/episode-1",
              downloadUrl: "/download/bedspacer/episode-1",
            },
          ],
          "home-service": [
            {
              title: "Episode 1",
              watchUrl: "/watch/home-service/episode-1",
              downloadUrl: "/download/home-service/episode-1",
            },
          ],
          pabuya: [
            {
              title: "Episode 1",
              watchUrl: "/watch/pabuya/episode-1",
              downloadUrl: "/download/pabuya/episode-1",
            },
          ],
          "do-you-think-i-am-s3xy": [
            {
              title: "Episode 1",
              watchUrl: "/watch/do-you-think-i-am-s3xy/episode-1",
              downloadUrl: "/download/do-you-think-i-am-s3xy/episode-1",
            },
          ],
          "lampas-langit": [
            {
              title: "Episode 1",
              watchUrl: "/watch/lampas-langit/episode-1",
              downloadUrl: "/download/lampas-langit/episode-1",
            },
          ],
          laruan: [
            {
              title: "Episode 1",
              watchUrl: "/watch/laruan/episode-1",
              downloadUrl: "/download/laruan/episode-1",
            },
          ],
          biyak: [
            {
              title: "Episode 1",
              watchUrl: "/watch/biyak/episode-1",
              downloadUrl: "/download/biyak/episode-1",
            },
          ],
          hugot: [
            {
              title: "Episode 1",
              watchUrl: "/watch/hugot/episode-1",
              downloadUrl: "/download/hugot/episode-1",
            },
          ],
          "x-deal-2": [
            {
              title: "Episode 1",
              watchUrl: "/watch/x-deal-2/episode-1",
              downloadUrl: "/download/x-deal-2/episode-1",
            },
          ],
          "bela-luna": [
            {
              title: "Episode 1",
              watchUrl: "/watch/bela-luna/episode-1",
              downloadUrl: "/download/bela-luna/episode-1",
            },
          ],
          "sabel-is-still-young": [
            {
              title: "Episode 1",
              watchUrl: "/watch/sabel-is-still-young/episode-1",
              downloadUrl: "/download/sabel-is-still-young/episode-1",
            },
          ],
          sandwich: [
            {
              title: "Episode 1",
              watchUrl: "/watch/sandwich/episode-1",
              downloadUrl: "/download/sandwich/episode-1",
            },
          ],
          "s3x-games": [
            {
              title: "Episode 1",
              watchUrl: "/watch/s3x-games/episode-1",
              downloadUrl: "/download/s3x-games/episode-1",
            },
          ],
          "silip-sa-apoy": [
            {
              title: "Episode 1",
              watchUrl: "/watch/silip-sa-apoy/episode-1",
              downloadUrl: "/download/silip-sa-apoy/episode-1",
            },
          ],
          eva: [
            {
              title: "Episode 1",
              watchUrl: "/watch/eva/episode-1",
              downloadUrl: "/download/eva/episode-1",
            },
          ],
          "bjj-woman-on-top": [
            {
              title: "Episode 1",
              watchUrl: "/watch/bjj-woman-on-top/episode-1",
              downloadUrl: "/download/bjj-woman-on-top/episode-1",
            },
          ],
          "kitty-k7": [
            {
              title: "Episode 1",
              watchUrl: "/watch/kitty-k7/episode-1",
              downloadUrl: "/download/kitty-k7/episode-1",
            },
          ],
        };
  
        // Load the episodes for the current movie slug
        this.episodes = episodesData[titleSlug] || [];
      },
      watchEpisode(title) {
          const episode = this.episodes.find((e) => e.title === title);
          if (episode) {
              window.location.href = episode.watchUrl; // Use window.location.href for an external link
          }
      },
      downloadEpisode(title) {
          const episode = this.episodes.find((e) => e.title === title);
          if (episode) {
              window.location.href = episode.downloadUrl; // Use window.location.href for the download link
          }
      }
    },
  };
  </script>
  
  <style scoped>
  button {
    margin-left: 10px;
  }
  </style>
  