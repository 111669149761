<template>
  <div class="md:px-0 px-1.5 ">
    <!-- <h1 class="text-2xl text-center">Search your Favorite Pinoy Movies</h1> -->
    <div class="mb-2 flex flex-col ">
      <span class="text-xl font-bold text-red-700">Disclaimer. </span>
      <span class="text-sm ">
        The videos featured on this site are not owned by us. All
        videos are hosted on third-party video players and platforms, which we
        do not control. We simply provide a platform for these videos to be
        accessed. All copyrights and intellectual property rights belong to the
        original content creators or owners. If you have any concerns regarding
        the content, please contact the respective third-party hosting site
        directly.
      </span>
    </div>

    <!-- Search Box -->
    <!-- <input
      type="text"
      v-model="searchQuery"
      placeholder="Search for a movie..."
    /> -->
    
    <div class="pt-2  mx-auto md:mx-0 text-gray-600 w-[100%] ">
        <input class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none w-[100%]  "
          type="search" v-model="searchQuery" name="search" placeholder="Search Available Movies here">
      </div>

    <!-- Displaying the filtered movies -->
    <ul
      class="flex flex-col gap-2 justify-center"
      v-if="searchQuery && filteredMovies.length"
    >
      <li
        class="flex flex-row justify-between md:justify-start items-center px-3 py-1.5 text-2xl outline-[0.5px] outline"
        v-for="movie in filteredMovies"
        :key="movie.title"
      >
      <router-link :to="`/ph-movies/${movie.slug}`">
        <span class="font-semibold">
          {{ movie.title }}
        </span>
      </router-link>
      <div class="flex gap-2">
          
          <router-link :to="`/ph-movies/${movie.slug}`">
          <button class="flex items-center">
            <box-icon name="play" color="green" size="2rem"></box-icon>
          </button>
          </router-link>

        <a href="https://watchmovie.life/vvmax" class="flex items-center">
          <box-icon name="down-arrow-circle" color="red" size="2rem"></box-icon>
        </a>
        </div>
      </li>
    </ul>

    <!-- Message if no results found and a search has been made -->
    <p v-if="searchQuery && !filteredMovies.length">No results found.</p>
    

    
  </div>
</template>

<script>
export default {
  name: "PhMovies",
  data() {
    return {
      searchQuery: "",
      movies: [
        { title: "Package Deal", slug: "package-deal" },
        { title: "Init", slug: "init" },
        { title: "Kaskasero", slug: "kaskasero" },
        { title: "Maharot", slug: "maharot" },
        { title: "Kaulayaw", slug: "kaulayaw" },
        { title: "Hiraya", slug: "hiraya" },
        { title: "Maliko", slug: "maliko" },
        { title: "Top 1", slug: "top-1" },
        { title: "Huwad", slug: "huwad" },
        { title: "Nurse Abi", slug: "nurse-abi" },
        { title: "Cita", slug: "cita" },
        { title: "Sisid Marino", slug: "sisid-marino" },
        { title: "Linya", slug: "linya" },
        { title: "Mahal Ko Ang Mahal Mo", slug: "mahal-ko-ang-mahal-mo" },
        { title: "Serbidoras", slug: "serbidoras" },
        { title: "Foursome", slug: "foursome" },
        { title: "Sugapa", slug: "sugapa" },
        { title: "Kulong", slug: "kulong" },
        { title: "Haslers", slug: "haslers" },
        { title: "Hilom", slug: "hilom" },
        { title: "Balinsasayaw", slug: "balinsasayaw" },
        { title: "Dirty Ice Cream", slug: "dirty-ice-cream" },
        { title: "Lady Guard", slug: "lady-guard" },
        { title: "Red Flag", slug: "red-flag" },
        { title: "Dayo", slug: "dayo" },
        { title: "Wanted: Girlfriend", slug: "wanted-girlfriend" },
        { title: "Late Bloomer", slug: "late-bloomer" },
        { title: "Sweet Release", slug: "sweet-release" },
        { title: "Ganti-Ganti", slug: "ganti-ganti" },
        { title: "Cheaters", slug: "cheaters" },
        { title: "Kasalo", slug: "kasalo" },
        { title: "TL", slug: "tl" },
        { title: "Rita", slug: "rita" },
        { title: "Mapanukso", slug: "mapanukso" },
        { title: "Salisihan", slug: "salisihan" },
        { title: "Hibang", slug: "hibang" },
        { title: "The Influencer", slug: "the-influencer" },
        { title: "Eks", slug: "eks" },
        { title: "Kabit", slug: "kabit" },
        { title: "Salitan", slug: "salitan" },
        { title: "Takas", slug: "takas" },
        { title: "Katas", slug: "katas" },
        {
          title: "Palipat-Lipat, Papalit-Palit",
          slug: "palipat-lipat-papalit-palit",
        },
        { title: "Salawahan", slug: "salawahan" },
        { title: "Pantasya ni Tami", slug: "pantasya-ni-tami" },
        { title: "Dilig", slug: "dilig" },
        { title: "Room Service", slug: "room-service" },
        { title: "Karinyo Brutal", slug: "karinyo-brutal" },
        { title: "Higop", slug: "higop" },
        { title: "Ahasss", slug: "ahasss" },
        { title: "Creak", slug: "creak" },
        { title: "Wild Flowers", slug: "wild-flowers" },
        { title: "Bula", slug: "bula" },
        { title: "Patikim-Tikim", slug: "patikim-tikim" },
        { title: "Call Me Alma", slug: "call-me-alma" },
        { title: "Manyak", slug: "manyak" },
        { title: "Tayuan", slug: "tayuan" },
        { title: "Star Dancer", slug: "star-dancer" },
        { title: "Kabayo", slug: "kabayo" },
        { title: "Paupahan", slug: "paupahan" },
        { title: "Balik Taya", slug: "balik-taya" },
        { title: "Suki", slug: "suki" },
        { title: "Selina’s Gold", slug: "selinas-gold" },
        { title: "Doblado", slug: "doblado" },
        { title: "Kaliwaan", slug: "kaliwaan" },
        { title: "Island of Desire", slug: "island-of-desire" },
        { title: "Kinsenas, Katapusan", slug: "kinsenas-katapusan" },
        { title: "Palitan", slug: "palitan" },
        { title: "Daddysitter", slug: "daddysitter" },
        { title: "Flirtatious", slug: "flirtatious" },
        { title: "Boso Dos", slug: "boso-dos" },
        { title: "Bedspacer", slug: "bedspacer" },
        { title: "Home Service", slug: "home-service" },
        { title: "Pabuya", slug: "pabuya" },
        { title: "Do You Think I Am S3xy", slug: "do-you-think-i-am-s3xy" },
        { title: "Lampas Langit", slug: "lampas-langit" },
        { title: "Laruan", slug: "laruan" },
        { title: "Biyak", slug: "biyak" },
        { title: "Hugot", slug: "hugot" },
        { title: "X-Deal 2", slug: "x-deal-2" },
        { title: "Bela Luna", slug: "bela-luna" },
        { title: "Sabel Is Still Young", slug: "sabel-is-still-young" },
        { title: "Sandwich", slug: "sandwich" },
        { title: "S3x Games", slug: "s3x-games" },
        { title: "Silip sa Apoy", slug: "silip-sa-apoy" },
        { title: "Eva", slug: "eva" },
        { title: "BJJ: Woman on Top", slug: "bjj-woman-on-top" },
        { title: "Kitty K7", slug: "kitty-k7" },
      ],
    };
  },
  computed: {
    filteredMovies() {
      const query = this.searchQuery.toLowerCase();
      return this.movies.filter((movie) =>
        movie.title.toLowerCase().includes(query)
      );
    },
  },
  methods: {
    watchMovie(slug) {
      this.$router.push(`/movies/${slug}`);
    },
    downloadMovie(slug) {
      // Implement download logic here
      window.location.href = `/download/${slug}`;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
}
button {
  margin-left: 10px;
}
</style>
