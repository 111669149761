<template>
  <div>
    <div class="mb-2 flex flex-col ">
      <span class="text-xl font-bold text-red-700">Disclaimer. </span>
      <span class="text-sm ">
        The videos featured on this site are not owned by us. All
        videos are hosted on third-party video players and platforms, which we
        do not control. We simply provide a platform for these videos to be
        accessed. All copyrights and intellectual property rights belong to the
        original content creators or owners. If you have any concerns regarding
        the content, please contact the respective third-party hosting site
        directly.
      </span>
    </div>
    <!-- Search Box -->
    <!-- <input
      type="text"
      v-model="searchQuery"
      placeholder="Search for a movie..."
    /> -->

    <!-- Displaying the filtered movies -->
    <!-- <ul v-if="searchQuery && filteredMovies.length">
      <li v-for="movie in filteredMovies" :key="movie.title">
        <router-link :to="`/movies/${movie.slug}`">{{
          movie.title
        }}</router-link>
        <button @click="watchMovie(movie.slug)">Watch</button>
        <button @click="downloadMovie(movie.slug)">Download</button>
      </li>
    </ul> -->

    <!-- Message if no results found and a search has been made -->
    <!-- <p v-if="searchQuery && !filteredMovies.length">No results found.</p> -->
  </div>
</template>

<script>
export default {
  name: "KdramaMovies",
  data() {
    return {
      searchQuery: "",
      movies: [
        { title: "Good Partner", slug: "good-partner-2024" },
        { title: "Romance in the House", slug: "romance-in-the-house-2024" },
        { title: "The Frog", slug: "the-frog-2024" },
        { title: "It's Okay, That's Love", slug: "its-okay-thats-love-2014" },
        {
          title: "Moon Lovers: Scarlet Heart Ryeo",
          slug: "moon-lovers-scarlet-heart-ryeo-2016",
        },
        { title: "The Lover", slug: "the-lover-2015" },
        { title: "Oh My Ghost", slug: "oh-my-ghost-2015" },
        { title: "Cheese in the Trap", slug: "cheese-in-the-trap-2016" },
        { title: "Another Miss Oh", slug: "another-miss-oh-2016" },
        { title: "Uncontrollably Fond", slug: "uncontrollably-fond-2016" },
        { title: "Move to Heaven", slug: "move-to-heaven-2021" },
        { title: "Reply 1997", slug: "reply-1997-2012-2013" },
        {
          title: "Who Are You: School 2015",
          slug: "who-are-you-school-2015-2015",
        },
        {
          title: "Weightlifting Fairy Kim Bok Joo",
          slug: "weightlifting-fairy-kim-bok-joo-2016",
        },
        { title: "Come and Hug Me", slug: "come-and-hug-me-2018" },
        {
          title: "Because This Is My First Life",
          slug: "because-this-is-my-first-life-2017",
        },
        { title: "SKY Castle", slug: "sky-castle-2018-2019" },
        {
          title: "20th Century Boy and Girl",
          slug: "20th-century-boy-and-girl-2017",
        },
        { title: "Her Private Life", slug: "her-private-life-2019" },
        { title: "I Am Not a Robot", slug: "i-am-not-a-robot-2019" },
        { title: "Save Me 2", slug: "save-me-2-2019" },
        { title: "Save Me", slug: "save-me-2017" },
        {
          title: "Under The Queen's Umbrella",
          slug: "under-the-queens-umbrella-2022",
        },
        { title: "Grand Prince", slug: "grand-prince-2018" },
        { title: "Just Between Lovers", slug: "just-between-lovers-2017-2018" },
        { title: "My Mister", slug: "my-mister-2018" },
        { title: "Drinking Solo", slug: "drinking-solo-2016" },
        { title: "Mystic Pop-Up Bar", slug: "mystic-pop-up-bar-2020" },
        { title: "The K2", slug: "the-k2-2016" },
        {
          title: "My Unfortunate Boyfriend",
          slug: "my-unfortunate-boyfriend-2015",
        },
        { title: "Princess Hours", slug: "princess-hours-2006" },
        { title: "Navillera", slug: "navillera-2021" },
        { title: "Girls’ Generation 1979", slug: "girls-generation-1979-2017" },
        { title: "Life on Mars", slug: "life-on-mars-2018" },
        { title: "Plus Nine Boys", slug: "plus-nine-boys-2014" },
        { title: "Personal Taste", slug: "personal-taste-2010" },
        { title: "Beautiful Mind", slug: "beautiful-mind-2016" },
        { title: "Age of Youth", slug: "age-of-youth-2016-2017" },
        { title: "Beautiful Gong Shim", slug: "beautiful-gong-shim-2016" },
        { title: "You Are So Beautiful", slug: "you-are-so-beautiful-2019" },
        { title: "Entertainer", slug: "entertainer-2016" },
        { title: "Extraordinary You", slug: "extraordinary-you-2019" },
        { title: "Shopping King Louie", slug: "shopping-king-louie-2016" },
        { title: "Sassy, Go Go", slug: "sassy-go-go-2015" },
        { title: "The Tale of Nokdu", slug: "the-tale-of-nokdu-2019" },
        { title: "A Poem a Day", slug: "a-poem-a-day-2018" },
        {
          title: "Clean With Passion For",
          slug: "clean-with-passion-for-2018-2019",
        },
        { title: "Chicago Typewriter", slug: "chicago-typewriter-2017" },
        { title: "Into the Ring", slug: "into-the-ring-2020" },
        {
          title: "The Smile Has Left Your Eyes",
          slug: "the-smile-has-left-your-eyes-2018",
        },
        { title: "Psychopath Diary", slug: "psychopath-diary-2019" },
        { title: "Yumi's Cells", slug: "yumis-cells-2021" },
        { title: "Pinocchio", slug: "pinocchio-2014" },
        { title: "Love With Flaws", slug: "love-with-flaws-2019-2020" },
        {
          title: "Shut Up Flower Boy Band",
          slug: "shut-up-flower-boy-band-2012",
        },
        { title: "Mr. Queen", slug: "mr-queen-2020" },
        { title: "Leverage", slug: "leverage-2019" },
        { title: "Tomorrow With You", slug: "tomorrow-with-you-2017" },
        { title: "Crash Landing on You", slug: "crash-landing-on-you-2019" },
        { title: "Strongest Deliveryman", slug: "strongest-deliveryman-2017" },
        { title: "Mirror of the Witch", slug: "mirror-of-the-witch-2016" },
        { title: "The Game: Towards Zero", slug: "the-game-towards-zero-2020" },
        { title: "Sweden Laundry", slug: "sweden-laundry-2015" },
        { title: "Mr. Sunshine", slug: "mr-sunshine-2018" },
        { title: "Reply 1994", slug: "reply-1994-2013" },
        { title: "Moorim School", slug: "moorim-school-2016" },
        { title: "Flower Boy Next Door", slug: "flower-boy-next-door-2013" },
        { title: "Beautiful World", slug: "beautiful-world-2019" },
        { title: "Nightmare Teacher", slug: "nightmare-teacher-2016" },
        { title: "Go Back Couple", slug: "go-back-couple-2017" },
        { title: "Imaginary Cat", slug: "imaginary-cat-2015-2016" },
        { title: "Your Honor", slug: "your-honor-2018" },
        { title: "Angry Mom", slug: "angry-mom-2015" },
        { title: "The Masters Sun", slug: "the-masters-sun-2013" },
        { title: "Cunning Single Lady", slug: "cunning-single-lady-2014" },
        {
          title: "Team Bulldog: Off-duty Investigation",
          slug: "team-bulldog-off-duty-investigation-2020",
        },
        { title: "Reply 1988", slug: "reply-1988-2015-2016" },
        {
          title: "What’s Wrong With Secretary Kim",
          slug: "whats-wrong-with-secretary-kim-2018",
        },
        {
          title: "Three Color Fantasy: Queen of the Ring",
          slug: "three-color-fantasy-queen-of-the-ring-2017",
        },
        { title: "Memorist", slug: "memorist-2020" },
        { title: "Rich Man", slug: "rich-man-2018" },
        { title: "Somehow 18", slug: "somehow-18-2017" },
        { title: "Good Doctor", slug: "good-doctor-2013" },
        { title: "Best Chicken", slug: "best-chicken-2019" },
        { title: "The Crowned Clown", slug: "the-crowned-clown-2019" },
        { title: "Dr. Romantic Season 3", slug: "dr-romantic-season-3-2023" },
        { title: "Lovely Runner", slug: "lovely-runner-2024" },
        { title: "Circle", slug: "circle-2017" },
        { title: "The Atypical Family", slug: "the-atypical-family-2024" },
        { title: "Fated to Love You", slug: "fated-to-love-you-2014" },
        { title: "Touch Your Heart", slug: "touch-your-heart-2019" },
        { title: "Radiant Office", slug: "radiant-office-2017" },
        { title: "Moving", slug: "moving-2023" },
        { title: "Vagabond", slug: "vagabond-2019" },
        { title: "Witch’s Love Episode", slug: "witchs-love-episode-2018" },
        { title: "You Drive Me Crazy!", slug: "you-drive-me-crazy-2018" },
        { title: "Kkondae Intern", slug: "kkondae-intern-2020" },
        {
          title: "My Girlfriend Is A Gumiho",
          slug: "my-girlfriend-is-a-gumiho-2010",
        },
        { title: "White Christmas", slug: "white-christmas-2011" },
        {
          title: "My ID Is Gangnam Beauty",
          slug: "my-id-is-gangnam-beauty-2018",
        },
        { title: "My First First Love 2", slug: "my-first-first-love-2-2019" },
        { title: "My First First Love", slug: "my-first-first-love-2019" },
        {
          title: "The Man Living In Our House",
          slug: "the-man-living-in-our-house-2016",
        },
        { title: "Orange Marmalade", slug: "orange-marmalade-2015" },
        { title: "Blood", slug: "blood-2015" },
        { title: "Hello Monster", slug: "hello-monster-2015" },
        { title: "About Time", slug: "about-time-2018" },
        {
          title: "The Secret Life of My Secretary",
          slug: "the-secret-life-of-my-secretary-2019",
        },
        { title: "100 Days My Prince", slug: "100-days-my-prince-2018" },
        { title: "Manhole: Feel So Good", slug: "manhole-feel-so-good-2017" },
        { title: "Mad for Each Other", slug: "mad-for-each-other-2021" },
        {
          title: "Angel's Last Mission: Love",
          slug: "angels-last-mission-love-2019",
        },
        { title: "Something About 1%", slug: "something-about-1-2016" },
        { title: "I Hear Your Voice", slug: "i-hear-your-voice-2013" },
        {
          title: "Please Come Back, Mister",
          slug: "please-come-back-mister-2016",
        },
        {
          title: "Sell Your Haunted House",
          slug: "sell-your-haunted-house-2021",
        },
        { title: "Emergency Couple", slug: "emergency-couple-2014" },
        { title: "The Witch's Diner", slug: "the-witchs-diner-2021" },
        { title: "Revolutionary Love", slug: "revolutionary-love-2016" },
        { title: "The Zombie Detective", slug: "the-zombie-detective-2020" },
        { title: "Class of Lies", slug: "class-of-lies-2019" },
        {
          title: "I Wanna Hear Your Song",
          slug: "i-wanna-hear-your-song-2019",
        },
        { title: "Hotel del Luna", slug: "hotel-del-luna-2019" },
        { title: "Doctors Episode", slug: "doctors-episode-2016" },
        { title: "You Raise Me Up", slug: "you-raise-me-up-2021" },
        {
          title: "Arthdal Chronicles: The Sword of Aramun",
          slug: "arthdal-chronicles-the-sword-of-aramun-2023",
        },
        { title: "Arthdal Chronicles", slug: "arthdal-chronicles-2019" },
        { title: "Graceful Family", slug: "graceful-family-2019" },
        { title: "Marriage Not Dating", slug: "marriage-not-dating-2014" },
        { title: "Rooftop Prince", slug: "rooftop-prince-2012" },
        { title: "Click Your Heart", slug: "click-your-heart-2016" },
        {
          title: "Dae Jang Geum Is Watching",
          slug: "dae-jang-geum-is-watching-2018-2019",
        },
        {
          title: "Memories of the Alhambra",
          slug: "memories-of-the-alhambra-2018-2019",
        },
        { title: "Modern Farmer", slug: "modern-farmer-2014" },
        { title: "Shadow Beauty", slug: "shadow-beauty-2021" },
        { title: "Catch the Ghost", slug: "catch-the-ghost-2019" },
        { title: "EXO Next Door", slug: "exo-next-door-2015" },
        { title: "Black Dog", slug: "black-dog-2019-2020" },
        { title: "Dr. Brain", slug: "dr-brain-2021" },
        { title: "My Holo Love", slug: "my-holo-love-2020" },
        { title: "The Best Hit", slug: "the-best-hit-2017" },
        { title: "Last Minute Romance", slug: "last-minute-romance-2017" },
        { title: "The Red Sleeve Cuff", slug: "the-red-sleeve-cuff-2021" },
        { title: "Itaewon Class", slug: "itaewon-class-2020" },
        { title: "The Beauty Inside", slug: "the-beauty-inside-2018" },
        { title: "Top Star Yoo Baek", slug: "top-star-yoo-baek-2018-2019" },
        { title: "Hyena", slug: "hyena-2020" },
        { title: "Hi Bye, Mama", slug: "hi-bye-mama-2020" },
        {
          title: "Drama Special Series Season 3: Adolescence Medley",
          slug: "drama-special-series-season-3-adolescence-medley-2013",
        },
        { title: "365: Repeat the Year", slug: "365-repeat-the-year-2020" },
        {
          title: "While You Were Sleeping",
          slug: "while-you-were-sleeping-2017",
        },
        {
          title: "Twenty-Five Twenty-One",
          slug: "twenty-five-twenty-one-2022",
        },
        {
          title: "Find Me in Your Memory",
          slug: "find-me-in-your-memory-2020",
        },
        { title: "The Heirs", slug: "the-heirs-2013" },
        { title: "Love Cells Season 2", slug: "love-cells-season-2-2010" },
        { title: "Love Cells", slug: "love-cells-2014" },
        { title: "Extracurricular", slug: "extracurricular-2020" },
        {
          title: "The World of the Married",
          slug: "the-world-of-the-married-2020",
        },
        { title: "Birthcare Center", slug: "birthcare-center-2020" },
        {
          title: "Romance is a Bonus Book",
          slug: "romance-is-a-bonus-book-2019",
        },
        {
          title: "The Liar and His Lover",
          slug: "the-liar-and-his-lover-2017",
        },
        { title: "Familiar Wife", slug: "familiar-wife-2018" },
        { title: "Idol: The Coup", slug: "idol-the-coup-2021" },
        { title: "My Only Love Song", slug: "my-only-love-song-2017" },
        { title: "One Ordinary Day", slug: "one-ordinary-day-2021" },
        {
          title: "Where Your Eyes Linger",
          slug: "where-your-eyes-linger-2020",
        },
        { title: "Meloholic", slug: "meloholic-2017" },
        { title: "Oh My Baby", slug: "oh-my-baby-2020" },
        { title: "She is Wow", slug: "she-is-wow-2013" },
        { title: "Her Lovely Heels", slug: "her-lovely-heels-2014" },
        { title: "Backstreet Rookie", slug: "backstreet-rookie-2020" },
        {
          title: "It's Okay to Not Be Okay",
          slug: "its-okay-to-not-be-okay-2020",
        },
        { title: "My Unfamiliar Family", slug: "my-unfamiliar-family-2020" },
        { title: "Tomorrow", slug: "tomorrow-2022" },
        { title: "Chip In", slug: "chip-in-2020" },
        { title: "Kill Me Heal Me", slug: "kill-me-heal-me-2015" },
        {
          title: "Alchemy of Souls Season 2: Light and Shadow",
          slug: "alchemy-of-souls-season-2-light-and-shadow-2022",
        },
      ],
    };
  },
  computed: {
    filteredMovies() {
      const query = this.searchQuery.toLowerCase();
      return this.movies.filter((movie) =>
        movie.title.toLowerCase().includes(query)
      );
    },
  },
  methods: {
    watchMovie(slug) {
      this.$router.push(`/movies/${slug}`);
    },
    downloadMovie(slug) {
      // Implement download logic here
      window.location.href = `/download/${slug}`;
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 20px;
  padding: 5px;
  font-size: 16px;
}
button {
  margin-left: 10px;
}
</style>
